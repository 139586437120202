import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config";
import { H1, P } from "./Typography";

const FullSection = styled.div`
  width: 90vw;
  margin-inline: auto;

  ${MEDIA_QUERIES.mobile} {
    width: 100vw;
  }
`;

const Main = styled.main`
  padding: 32px 0 72px 0;
  text-align: center;

  ${H1} {
    margin-bottom: 32px;
  }

  ${P} {
    margin-left: auto;
    margin-right: auto;
    max-width: 587px;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 0 16px 32px 16px;
    text-align: left;

    ${H1} {
      margin: 32px 0;
    }
  }
`;

FullSection.Main = Main;

export default FullSection;
