import React from "react";
import SwiperCore, { Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config";
import imageSlider0 from "../../assets/images/studio-slider-0.png";
import imageSlider1 from "../../assets/images/studio-slider-1.png";
import imageSlider2 from "../../assets/images/studio-slider-2.png";
import imageSlider3 from "../../assets/images/studio-slider-3.png";
import imageSlider4 from "../../assets/images/studio-slider-4.png";
import imageSlider5 from "../../assets/images/studio-slider-5.png";
import imageSlider6 from "../../assets/images/studio-slider-6.png";
import imageSlider7 from "../../assets/images/studio-slider-7.png";
import imageSlider8 from "../../assets/images/studio-slider-8.png";
import imageSlider9 from "../../assets/images/studio-slider-9.png";
import imageSlider10 from "../../assets/images/studio-slider-10.png";
import imageSlider11 from "../../assets/images/studio-slider-11.png";
import imageSlider12 from "../../assets/images/studio-slider-12.png";
import imageSlider13 from "../../assets/images/studio-slider-13.png";
import imageSlider14 from "../../assets/images/studio-slider-14.png";
import imageSlider15 from "../../assets/images/studio-slider-15.png";
import imageSlider16 from "../../assets/images/studio-slider-16.png";
import imageSlider17 from "../../assets/images/studio-slider-17.png";
import imageSlider18 from "../../assets/images/studio-slider-18.png";
import imageSlider19 from "../../assets/images/studio-slider-19.png";
import imageSlider20 from "../../assets/images/studio-slider-20.png";
import imageSlider21 from "../../assets/images/studio-slider-21.png";
import imageSlider22 from "../../assets/images/studio-slider-22.png";
import imageSlider23 from "../../assets/images/studio-slider-23.png";
import imageSlider24 from "../../assets/images/studio-slider-24.png";
import imageSlider25 from "../../assets/images/studio-slider-25.png";
import imageSlider26 from "../../assets/images/studio-slider-26.png";

SwiperCore.use([Autoplay]);

const logos = [
  imageSlider0,
  imageSlider1,
  imageSlider2,
  imageSlider3,
  imageSlider4,
  imageSlider5,
  imageSlider6,
  imageSlider7,
  imageSlider8,
  imageSlider9,
  imageSlider10,
  imageSlider11,
  imageSlider12,
  imageSlider13,
  imageSlider14,
  imageSlider15,
  imageSlider16,
  imageSlider17,
  imageSlider18,
  imageSlider19,
  imageSlider20,
  imageSlider21,
  imageSlider22,
  imageSlider23,
  imageSlider24,
  imageSlider25,
  imageSlider26,
];

const StyledSwiper = styled(Swiper)`
  width: 100%;
  margin-bottom: 72px;
  margin-inline: auto;

  @media (max-width: 540px) {
    margin-bottom: 0;
  }
`;

const AutoSlider = () => {
  return (
    <StyledSwiper
      spaceBetween={60}
      slidesPerView={3}
      loop={true}
      modules={[Autoplay]}
      autoplay={{ delay: 3000 }}
      breakpoints={{
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 6,
        },
        1440: {
          slidesPerView: 8,
        },
      }}
    >
      {logos.map((logo, index) => (
        <SwiperSlide key={index}>
          <Slide src={logo} alt={`Logo ${index + 1}`} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

export default AutoSlider;

function Slide({ src, alt }) {
  return (
    <div
      css={`
        width: 145px;
        height: 88px;

        ${MEDIA_QUERIES.mobile} {
          width: 80px;
          height: 48px;
          text-align: center;
        }
      `}
    >
      <img
        css={`
          height: 100%;
          width: 100%;
          object-fit: contain;
          object-position: center;
        `}
        src={src}
        alt={alt}
      />
    </div>
  );
}
