import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import { H1, P } from "../components/common/Typography";
import Studio from "../components/studios/Studio";
import imageStudio1 from "../assets/images/studio-1.jpg";
import imageStudio2 from "../assets/images/studio-2.jpg";
import imageStudio3 from "../assets/images/studio-3.jpg";
import imageStudio11 from "../assets/images/studio-1-1.jpg";
import imageStudio12 from "../assets/images/studio-1-2.jpg";
import imageStudio13 from "../assets/images/studio-1-3.jpg";
import imageStudio21 from "../assets/images/studio-2-1.jpg";
import imageStudio22 from "../assets/images/studio-2-2.jpg";
import imageStudio23 from "../assets/images/studio-2-3.jpg";
import imageStudio31 from "../assets/images/studio-3-1.jpg";
import imageStudio32 from "../assets/images/studio-3-2.jpg";
import imageStudio33 from "../assets/images/studio-3-3.jpg";
import { MEDIA_QUERIES } from "../config";
import SEO from "../components/common/SEO";
import FullSection from "../components/common/FullSection";
import AutoSlider from "../components/studios/AutoSlider";

const TITLE = "Ενοικίαση χώρου";

function StudiosPage() {
  return (
    <Layout>
      <SEO title={TITLE} />
      <main>
        <Section
          css={`
            text-align: center;
            border-bottom: 1px solid #000;

            ${H1} {
              margin-bottom: 32px;
              margin-top: 64px;
            }

            ${P} {
              margin-bottom: 72px;
            }

            ${MEDIA_QUERIES.mobile} {
              border: none;
              text-align: left;
              padding: 0 16px 32px 16px;

              ${H1} {
                margin-top: 0;
              }

              ${P} {
                margin-bottom: 0;
              }
            }
          `}
        >
          <H1>{TITLE}</H1>
          <P>
            Η Σχολή μας μπορεί να φιλοξενήσει τις πιο απαιτητικές καλλιτεχνικές
            εκδηλώσεις. <br />
            Σεμινάρια, Φεστιβάλ, πρόβες Ομάδων Χορού και Θεάτρου, μαθήματα
            χορού, τηλεοπτικές παραγωγές, εκθέσεις ζωγραφικής, φωτογραφίας,
            παρουσιάσεις βιβλίων, εταιρικές εκδηλώσεις, διαλέξεις, συνέδρια
            εταιρειών, πάρτι.
            <br />
            <br />
            Η ενοικίαση γίνεται με βάση τη χωρητικότητα των αιθουσών.
            <br />
            <br /> • 3 αίθουσες με εμβαδόν (80 – 180 – 70) τετραγωνικά
            αντίστοιχα <br />
            • Μεγάλους χώρους υποδοχής - σαλόνια <br />
            • Καφέ-μπαρ με εμβαδόν 50 τετραγωνικά πλήρως εξοπλισμένο με
            καθιστικό <br />
            • Αποδυτήρια παιδικά, γυναικεία, ανδρικά με ντουλάπια κατάλληλα για
            τη σωστή και ασφαλή φύλαξη των αντικειμένων σας
            <br />
            • 4 ντουζιέρες – 8 τουαλέτες <br />• Αποθηκευτικούς χώρους
          </P>
        </Section>
        <FullSection
          css={`
            text-align: center;

            ${H1} {
              margin-bottom: 72px;
              margin-top: 64px;
            }

            ${MEDIA_QUERIES.mobile} {
              text-align: left;
              padding: 0 16px 32px 16px;

              ${H1} {
                margin-bottom: 32px;
                margin-top: 64px;
              }
            }
          `}
        >
          <H1>Συνεργαζόμενες Εταιρείες</H1>
          <AutoSlider />

          <div
            css={`
              border-bottom: 1px solid #000;
              max-width: 1024px;
              margin-inline: auto;

              ${MEDIA_QUERIES.mobile} {
                max-width: 100%;
                border: none;
              }
            `}
          ></div>
        </FullSection>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr;
            gap: 32px;
          `}
        >
          <Studio
            id="studio-1"
            name="Studio 01"
            images={[
              {
                src: imageStudio1,
                alt: "Studio 1",
              },
              {
                src: imageStudio11,
                alt: "Studio 1 1",
              },
              {
                src: imageStudio12,
                alt: "Studio 1 2",
              },
              {
                src: imageStudio13,
                alt: "Studio 1 3",
              },
            ]}
          >
            <ul>
              <li>80 τμ</li>
              <li>Ύψος οροφής 5 μέτρα</li>
              <li>Μήκος 9,30 Χ 9,12 μέτρα</li>
              <li>Επαγγελματικό ξύλινο πάτωμα TARKETT - FRANCE</li>
              <li>
                Χαρακτηριστικά : Reflex M Evolution, Multilayer wood floor on a
                wood sub- construction with foam integrated.
              </li>
              <li>PERFORMANCE DECLARATION - shock absorption 58%</li>
              <li>Ειδικό πάτωμα για Χορό που αποροφάει τους κραδασμούς.</li>
            </ul>
            <ul>
              <li>Επαγγελματικά συστήματα ήχου</li>
              <li>Πιάνο</li>
              <li>Ηχομόνωση</li>
              <li>Μπάρες Χορού</li>
              <li>Καθρέπτες με ειδικό φίλτρο ασφαλείας</li>
              <li>Ειδικός φωτισμός</li>
              <li>Κλιματιστικό ψύξης-θέρμανσης</li>
              <li>2 ανεμιστήρες οροφής</li>
              <li>Εξαερισμός</li>
            </ul>
          </Studio>
          <Studio
            id="studio-2"
            name="Studio 02"
            images={[
              {
                src: imageStudio2,
                alt: "Studio 2",
              },
              {
                src: imageStudio21,
                alt: "Studio 2 1",
              },
              {
                src: imageStudio22,
                alt: "Studio 2 2",
              },
              {
                src: imageStudio23,
                alt: "Studio 2 3",
              },
            ]}
          >
            <ul>
              <li>180 τμ</li>
              <li>Ύψος οροφής 5 μέτρα</li>
              <li>Μήκος 20,30 Χ 9,12 μέτρα</li>
              <li>Επαγγελματικό ξύλινο πάτωμα TARKETT - FRANCE</li>
              <li>
                Χαρακτηριστικά : Reflex M Evolution, Multilayer wood floor on a
                wood sub-construction with foam integrated.
              </li>
              <li>PERFORMANCE DECLARATION - shock absorption 58%</li>
              <li>Ειδικό πάτωμα για Χορό που αποροφάει τους κραδασμούς.</li>
              <li>Επαγγελματικά συστήματα ήχου</li>
              <li>Πιάνο</li>
            </ul>
            <ul>
              <li>Ηχομόνωση</li>
              <li>Μπάρες Χορού</li>
              <li>Καθρέπτες με ειδικό φίλτρο ασφαλείας</li>
              <li>Ειδικός φωτισμός</li>
              <li>Φυσικός εξαερισμός</li>
              <li>2 ανεμιστήρες οροφής</li>
              <li>2 κλιματιστικά ψύξης/θέρμανσης</li>
              <li>Διπλά ηχομονωτικά, βελούδινες κουρτίνες στα παράθυρα</li>
              <li>Κουρτίνες στους καθρέπτες</li>
            </ul>
          </Studio>
          <Studio
            id="studio-3"
            css={`
              margin-bottom: 140px;
            `}
            name="Studio 03"
            images={[
              {
                src: imageStudio3,
                alt: "Studio 4",
              },
              {
                src: imageStudio31,
                alt: "Studio 3 1",
              },
              {
                src: imageStudio32,
                alt: "Studio 32",
              },
              {
                src: imageStudio33,
                alt: "Studio 3 3",
              },
            ]}
          >
            <ul>
              <li>70 τμ</li>
              <li>Ύψος οροφής 5 μέτρα</li>
              <li>Μήκος 12,84 Χ 4,95 μέτρα</li>
              <li>
                Επαγγελματικό Αθλητικό δάπεδο TARAFLEX SPORT M PERFORMANCE της
                GERFLOR απο Γαλλία
              </li>
              <li>Χαρακτηριστικά : Πάχος 9,1mm</li>
              <li>Ειδικό πάτωμα για Χορό που αποροφάει τους κραδασμούς.</li>
              <li>Επαγγελματικά συστήματα ήχου</li>
              <li>Ηχομόνωση</li>
            </ul>
            <ul>
              <li>Μπάρες Χορού</li>
              <li>Καθρέπτες με ειδικό φίλτρο ασφαλείας</li>
              <li>Ειδικός φωτισμός</li>
              <li>Φυσικός εξαερισμός</li>
              <li>
                Ειδικά πανιά εγκατεστημένα απο την οροφή για μαθήματα AERIAL{" "}
              </li>
              <li>Ειδικά στρώματα πάχους 20cm για την ασφάλεια των μαθητών</li>
              <li>2 ηλεκτρικά θερμαντικά σώματα</li>
              <li>Επιδαπέδιοι ανεμιστήρες</li>
            </ul>
          </Studio>
        </div>
      </main>
    </Layout>
  );
}

export default StudiosPage;
