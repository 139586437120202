import React from "react";
import Section from "../common/Section";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import IconSquare from "../common/IconSquare";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import { H3 } from "../common/Typography";
import Button from "../common/Button";
import { MEDIA_QUERIES } from "../../config";
import { Link } from "gatsby";

SwiperCore.use([Navigation, Pagination]);

function Studio({ id, images = [], name, children, ...props }) {
  const nextEl = `swiper-next-${id}`;
  const prevEl = `swiper-prev-${id}`;
  const paginationEl = `swiper-pagination-${id}`;

  return (
    <Section
      css={`
        display: grid;
        grid-template-columns: 381px 1fr;
        gap: 55px;
        padding: 55px 0;
        border-bottom: 1px solid #000;

        ${MEDIA_QUERIES.mobile} {
          padding: 0;
          border: none;
          grid-template-columns: 100vw;
        }
      `}
      {...props}
    >
      <div>
        <Swiper
          loop
          slidesPerView={1}
          navigation={{
            nextEl: `.${nextEl}`,
            prevEl: `.${prevEl}`,
          }}
          pagination={{
            el: `.${paginationEl}`,
            clickable: true,
          }}
        >
          {images.map(image => (
            <SwiperSlide key={image.alt}>
              <Slide src={image.src} alt={image.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          css={`
            display: flex;
            margin-top: 32px;
            align-items: center;
            justify-content: space-between;

            ${MEDIA_QUERIES.mobile} {
              padding: 0 16px;
            }
          `}
        >
          <IconSquare className={prevEl}>
            <ChevronLeft />
          </IconSquare>
          <div
            className={paginationEl}
            css={`
              position: static;
              display: grid;
              grid-auto-flow: column;
              gap: 8px;

              .swiper-pagination-bullet {
                width: 11px;
                height: 11px;
                border: solid 1px #000;
                background: transparent;
                opacity: 1;
              }

              .swiper-pagination-bullet-active {
                background: #000;
              }
            `}
          ></div>
          <IconSquare className={nextEl}>
            <ChevronRight />
          </IconSquare>
        </div>
      </div>
      <div
        css={`
          display: flex;
          flex-direction: column;
          height: 100%;

          ${H3} {
            text-transform: uppercase;
            font-weight: 800;
            margin-bottom: 32px;
          }

          ${Button} {
            width: 238px;
            align-self: flex-end;
            margin-top: auto;
          }

          ul > li {
            font-size: 14px;
            line-height: 1.57;
          }

          ul > li:not(:last-child) {
            margin-bottom: 8px;
          }

          ${MEDIA_QUERIES.mobile} {
            padding: 0 16px;

            ${H3} {
              margin-bottom: 0;
            }

            ${Button} {
              width: 100%;
            }

            ul {
              width: 100vw;
              padding: 0 16px;
            }
          }
        `}
      >
        <H3>{name}</H3>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;

            ${MEDIA_QUERIES.mobile} {
              grid-template-columns: 1fr;

              ul:last-child {
                margin: 0 0 32px 0;
              }
            }
          `}
        >
          {children}
        </div>
        <Button.Arrowed as={Link} to="/contact">
          Μάθετε Περισσότερα
        </Button.Arrowed>
      </div>
    </Section>
  );
}

function Slide({ src, alt }) {
  return (
    <div
      css={`
        width: 375px;
        height: 358px;

        ${MEDIA_QUERIES.mobile} {
          width: 100%;
          text-align: center;
        }
      `}
    >
      <img
        css={`
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        `}
        src={src}
        alt={alt}
      />
    </div>
  );
}

export default Studio;
